var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a8a2946010bb4ddce9a1a81dfe07f8b4f43ee335"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENV = process.env.APP_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://73e37483277e401bb1dc307e0cece70f@o224443.ingest.sentry.io/6232404',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: APP_ENV === 'staging' ? 1.0 : 0.1,
  environment: APP_ENV,
  hideSourceMaps: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
